import React from 'react'
import './Loading.scss'

const Loading = (props) => {

    return (
        <div className={`loading ${props.loading && "show"}`}>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loading