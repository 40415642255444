import React from 'react'
import './Delete.scss'

const Delete = (props) => (
    <div ref={props.refs} className={`delete_wrapper ${props.state === "entering" || props.state === "entered" ? "active" : ""}`}>
        <div className="delete_btns">
            <div className="delete_y_wrapper">
                <p>Are you sure?</p>
                <div className="delete_btn" onClick={props.deleteItem}>Delete</div>
            </div>
            <div className="delete_cencel" onClick={props.closeDelete}>Cencel</div>
        </div>
        <div className="delete_shadow" onClick={props.closeDelete}></div>
    </div>
)

export default Delete