import axios from 'axios'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Add.scss'

const Add = () => {
    const [text,setText] = useState({
        text: ""
    })
    const [err,setErr] = useState(false)

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(text.text===""){
            setErr(true)
        }else{
            try{
                const res = await axios.post(process.env.REACT_APP_MY_SERVER_DOMAIN+"/?add=1", text)
                if(res.data==="OK"){
                    navigate("/")
                }
            } catch(err){
                console.log(err)
            }
        }
    }

    return (
        <div className="form_wrapper">
            <h1>Add</h1>
            <form>
                <textarea rows="6" placeholder="Enter text..." onChange={(e)=>setText({text: e.target.value})} value={text.text} onFocus={()=>setErr(false)}></textarea>
                {err && <span className="error">Field is empty</span>}
                <button type="submit" onClick={handleSubmit}>Add</button>
                <Link to="/" className="back">Home page</Link>
            </form>

        </div>
    )
}

export default Add