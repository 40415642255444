import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Home.scss'
import DeleteImg from '../../img/delete.png'
import Edit from '../../img/edit.png'
import Check from '../../img/check.png'
import Uncheck from '../../img/times.png'
import Loading from '../../components/loading/Loading'
import Delete from '../../components/delete/Delete'
import { Transition } from 'react-transition-group'
import { useRef } from 'react'
import LoadingSkeleton from '../../components/loadingSkeleton/LoadingSkeleton'

const Home = () => {

    const [todos,SetTodos] = useState([])
    const [loading,setLoading] = useState(false)
    const [loadingList,setLoadingList] = useState(true)
    const [sortBy,setSortBy] = useState("2")
    const [showDelete,setShowDelete] = useState(false)
    const [deleteId,setDeleteId] = useState(null)
    const nodeRef = useRef(null)

    useEffect(()=>{
        const fetchAllBooks = async () => {
            const res = await axios.get(process.env.REACT_APP_MY_SERVER_DOMAIN+"/?fetch=1")
            SetTodos(res.data)
            setLoadingList(false)
        }

        fetchAllBooks()
        return ()=>{
            fetchAllBooks()
        }
    },[])

    const changeStatus = async (id) => {
        setLoading(true)
        try{
            const resp = await axios(process.env.REACT_APP_MY_SERVER_DOMAIN+"/?changeStatus="+id)
            SetTodos(resp.data)
            setLoading(false)
        }catch(err){
            console.log(err)
        }
    }

    const deleteItemShow = (id) => {
        setShowDelete(true)
        setDeleteId(id)
    }
    
    const deleteItem = async () => {
        setLoading(true)
        try{
            await axios(process.env.REACT_APP_MY_SERVER_DOMAIN+"/?delete="+deleteId)
            const newList = todos.filter((item) => item.id !== deleteId);
            SetTodos(newList)
            setShowDelete(false)
            setDeleteId(null)
            setLoading(false)
        }catch(err){
            console.log(err)
        }
    }

    const sortList = (filt) => {
        setSortBy(filt)
    }

    const closeDelete = (filt) => {
        setShowDelete(false)
    }

    return (
        <>
        <Loading loading={loading} />
        <Transition in={showDelete} timeout={300} mountOnEnter unmountOnExit nodeRef={nodeRef}>
            {state => (
                <Delete refs={nodeRef} closeDelete={closeDelete} deleteItem={deleteItem} state={state} />
            )}
        </Transition>
        <h1>To Do App</h1>
        <div className="list_wrapper">
            
            {
                loadingList 
                ? <LoadingSkeleton />
                : todos.length>0 
                    ? todos.filter((item)=>sortBy<2 ? item.status === `${sortBy}` : true).sort((a, b) => a.status - b.status).map((item)=>(
                        <div className={`item item_id_${item.id} ${item.status === "1" ? "completed" : ""}`} key={item.id}>
                            <p>{item.text}</p>
                            <div className="btns">
                                <div onClick={()=>changeStatus(item.id)}><img src={ item.status === "1" ? Uncheck : Check} alt="Check" /></div>
                                <div><Link to={`/edit/${item.id}`}><img src={Edit} alt="Edit" /></Link></div>
                                <div onClick={()=>deleteItemShow(item.id)}><img src={DeleteImg} alt="Delete" /></div>
                            </div>
                        </div>
                    ))
                    : <p className="empty">Page is empty<br />Please add new one</p>
            }
        </div>
        <div className="status_bar">
            <button className={sortBy==="2" ? "active" : ""} onClick={()=>sortList("2")}>All</button>
            <button className={sortBy==="0" ? "active" : ""} onClick={()=>sortList("0")}>Active</button>
            <button className={sortBy==="1" ? "active" : ""} onClick={()=>sortList("1")}>Completed</button>
            <Link to="/add"><div className="add_new">Add New</div></Link>
        </div>
        </>
    )
}

export default Home