import {BrowserRouter, Routes, Route} from "react-router-dom"
import './app.scss'
import Home from "./pages/home/Home";
import Add from "./pages/add/Add";
import Edit from "./pages/edit/Edit";

function App() {
  return (
    <div className="container">
        <div className="app_wrapper">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/add" element={<Add />} />
              <Route path="/edit/:id" element={<Edit />} />
            </Routes>
          </BrowserRouter>
        </div>
    </div>
  );
}

export default App;
