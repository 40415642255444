import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import './Edit.scss'

const Add = () => {
    const [text,setText] = useState({
        text: ""
    })
    const [err,setErr] = useState(false)
    const navigate = useNavigate()
    
    const params = useParams()
    const itemId = params.id

    useEffect(()=>{
        const getInfo = async () => {
            const response = await axios.get(process.env.REACT_APP_MY_SERVER_DOMAIN+"/?getInfo="+itemId)
            setText(response.data)
        }

        getInfo()
        return ()=>{
            getInfo()
        }
    },[itemId])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(text.text===""){
            setErr(true)
        }else{
            try{
                const res = await axios.post(process.env.REACT_APP_MY_SERVER_DOMAIN+"/?update="+itemId, text)
                if(res.data==="OK"){
                    navigate("/")
                }
            } catch(err){
                console.log(err)
            }
        }
    }

    return (
        <div className="form_wrapper">
            <h1>Update</h1>
            <form>
                <textarea rows="6" placeholder="Enter text..." onChange={(e)=>setText({text: e.target.value})} value={text.text} onFocus={()=>setErr(false)}></textarea>
                {err && <span className="error">Field is empty</span>}
                <button type="submit" onClick={handleSubmit}>Update</button>
                <Link to="/" className="back">Home page</Link>
            </form>

        </div>
    )
}

export default Add